import { Await, NavigateFunction, Outlet, useNavigate } from 'react-router-dom';
import { Suspense, useEffect, useMemo, useRef } from 'react';
import PageLoading from '@/components/PageLoading';
import {
  getLevelConfig,
  getSysStatus,
  getUserProfile,
  signin,
  SysStatusEnum,
} from '@/api/game';
import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { LoadingProvider } from '@/components/LoadingProvider';
import { isTMA } from '@/utils/env';
import { getInviteCode, setInviteCode } from '@/utils/inviteCode';
import { getDrawConfig, getSynthesisConfig, getOwnedRewards } from '@/api/draw';
import { ThirdRoutes } from '@/const/env';
import store from 'store';
import { getMiniNodeRangePoints } from '@/api/mininode';

const getInviteParam = () => {
  const url = new URL(window.location.href);
  const inviteCode = url.searchParams.get('invite');
  if (inviteCode) {
    setInviteCode(inviteCode);
  }
  return inviteCode ? inviteCode : getInviteCode() || '';
};

const preloadVideo = (src: string) =>
  new Promise<string>((resolve, reject) => {
    const video = new Audio();
    video.src = src;
    video.preload = 'auto';
    video.load();
    video.oncanplaythrough = () => {
      resolve(src);
    };
    video.onerror = () => {
      reject(src);
    };
  });

const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
};
const loader = async (navigate: NavigateFunction) => {
  const url = window.location.pathname;
  const { startParam, initData } = retrieveLaunchParams();

  const isTG = await isTMA();

  const signInParams = {
    invitation_code: isTG
      ? startParam && startParam?.toString
        ? startParam?.toString()
        : ''
      : getInviteParam(),
  };

  const results = await Promise.allSettled([
    getSysStatus(),
    signin(signInParams),
  ]);

  const sysStatus = results[0].status === 'fulfilled' ? results[0].value : null;
  if (
    sysStatus?.status === SysStatusEnum.Open &&
    !sysStatus?.list?.includes(Number(initData?.user?.id))
  ) {
    navigate(`/upgrading?msg=${encodeURI(sysStatus?.msg)}`, { replace: true });
    return;
  }

  const payload = await Promise.allSettled([
    getLevelConfig(),
    getUserProfile(),
    getDrawConfig(),
    getOwnedRewards(),
    getSynthesisConfig(),
    getMiniNodeRangePoints(),
  ]);

  const levelConfigs =
    payload[0].status === 'fulfilled' ? payload[0].value : undefined;
  const userProfile =
    payload[1].status === 'fulfilled' ? payload[1].value : undefined;
  const drawConfig =
    payload[2].status === 'fulfilled' ? payload[2].value : undefined;
  const ownedRewards =
    payload[3].status === 'fulfilled' ? payload[3].value : undefined;
  const synthesisMaterials =
    payload[4].status === 'fulfilled' ? payload[4].value : undefined;
  const rangePoints =
    payload[5].status === 'fulfilled' ? payload[5].value : undefined;

  const tokenUrls =
    drawConfig?.party_tokens_list?.map((token) => token.icon_url) || [];
  const tokenPartyUrls =
    drawConfig?.party_tokens_list?.map((token) => token.party_icon_url) || [];
  Promise.all([preloadVideo('/video/draw.mp4')]);
  Promise.all(tokenUrls.map((url) => preloadImage(url)));
  Promise.all(tokenPartyUrls.map((url) => preloadImage(url)));

  const urls = [
    ThirdRoutes.TikTokLogin,
    ThirdRoutes.SnapChatLogin,
    ThirdRoutes.TikTok,
    ThirdRoutes.SnapChat,
  ];
  const currentMiniNodeModalCount = store.get('isShowMiniNodeModal');
  if (!isTG && !userProfile) {
    if (url === ThirdRoutes.TikTok) {
      navigate(ThirdRoutes.TikTokLogin, { replace: true });
    } else if (url === ThirdRoutes.SnapChat) {
      navigate(ThirdRoutes.SnapChatLogin, { replace: true });
    } else if (!urls.includes(url)) {
      navigate('/login', { replace: true });
    }
  } else if ((currentMiniNodeModalCount || 1) <= 10) {
    store.set('isShowMiniNodeModal', (currentMiniNodeModalCount || 1) + 1);
    navigate('/moreMiniNode');
  } else if (initData?.startParam && initData?.startParam === 'draw') {
    navigate('/draw', { replace: true });
  } else if (initData?.startParam && initData?.startParam === 'airdrop') {
    navigate('/airdrop', { replace: true });
  } else if (url === '/') {
    navigate('/index', { replace: true });
  }

  return {
    userProfile,

    levelConfigs,

    drawConfig,

    ownedRewards,

    synthesisMaterials,

    rangePoints,

    isH5: !isTG,
  };
};

const isSafariOrIOS = () => {
  const userAgent = navigator.userAgent;
  return (
    /iPhone|iPad|iPod|Safari/i.test(userAgent) &&
    !/Chrome|CriOS|OPR|Edge/i.test(userAgent)
  );
};

const BasicLayout = () => {
  const navigate = useNavigate();
  const loaderCache = useMemo(() => loader(navigate), []);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const isSafari = isSafariOrIOS();
    const setHeight = () => {
      if (containerRef.current) {
        if (isSafari) {
          containerRef.current.style.height = `${window.innerHeight}px`;
        } else {
          containerRef.current.style.height = '100vh';
        }
      }
    };

    setHeight();
    window.addEventListener('resize', setHeight);

    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  return (
    <LoadingProvider>
      <div
        ref={containerRef}
        className="w-[375px] mx-auto container overflow-y-auto"
      >
        <Suspense fallback={<PageLoading />}>
          <Await resolve={loaderCache}>
            <Outlet />
          </Await>
        </Suspense>
      </div>
    </LoadingProvider>
  );
};

export default BasicLayout;
