import { api, BaseResponse } from '.';
import { Project, SubTaskEntity } from './mine';

/*
 * Created Date: August 19th 2024, 10:52:22 am
 * Author: zhoupengcheng
 * -----
 * Last Modified: August 27th 2024, 10:55:59 pm
 */
export type Task = Project & { task_list?: SubTaskEntity[] };

export const getTaskList = async () => {
  const res = await api.get('task/v1/list').json<BaseResponse<Task[]>>();

  return res.data;
};

export const getAirdropList = async (project_tag: string) => {
  const res = await api.get('task/v1/project_task_list', {
    searchParams: {
      project_tag
    }
  }).json<BaseResponse<Task>>();

  return res.data;
};

export const verifyTask = async (params: {
  task_tag: string;
  ad_id?: string;
  as_platform_username?: string;
  as_platform?: string;
}) => {
  const res = await api
    .post('task/v1/verify/task', {
      json: params,
    })
    .json<BaseResponse<{ reward: number; status: number; verify: boolean }>>();

  return res;
};
