import { LoginResult } from '@/utils/mockRawData';
import request from '.';

export const loginWithTG = async (params: LoginResult) => {
  const res: { rawdata: string } = await request('post', 'telegram/oauth/callback', {
    json: params,
  });

  return res;
};
