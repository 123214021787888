import airdropBannerBg from '@/assets/images/airdrop/banner.png';
import airdropBg from '@/assets/images/airdrop/bg.png';
import airdropClose from '@/assets/images/airdrop/close.svg';
import airdropTip from '@/assets/images/airdrop/tip.png';
import airdropPolygon from '@/assets/images/airdrop/polygon.svg';
import copyIcon from '@/assets/images/airdrop/copy.png';
import TikTokIcon from '@/assets/images/airdrop/tiktok.png';
import checkedIcon from '@/assets/images/common/checked_icon.png';
import comingSoon from '@/assets/images/common/comming_soon.png';
import close from '@/assets/images/common/close.png';
import coin from '@/assets/images/common/coin.png';
import coin2 from '@/assets/images/common/coin2.png';
import coin3 from '@/assets/images/common/coin3.png';
import success from '@/assets/images/common/success.png';
import error from '@/assets/images/common/error.png';
import symbol from '@/assets/images/common/symbol.png';
import volume from '@/assets/images/common/volume.png';
import volumeControl from '@/assets/images/common/volume_control.png';
import headerZooIcon from '@/assets/images/header/zoo_icon.png';
import headerStarIcon from '@/assets/images/header/star_icon.png';
import headerCrystalIcon from '@/assets/images/header/crystal_icon.png';
import headerCoinIcon from '@/assets/images/header/coin_icon.png';
import headerAssetsIcon from '@/assets/images/header/assets.png';
import gameAvatarDefault from '@/assets/images/game/avatar_default.jpg';
import gameLevelOld from '@/assets/images/game/level_old.png';
import gameAirDropIcon from '@/assets/images/game/airdropIcon.png';
import gameLevelNew from '@/assets/images/game/level_new.png';
import gameCheckInIcon from '@/assets/images/game/checkin_icon.png';
import gameTabiPartyIcon from '@/assets/images/game/tabi_party_icon.png';
import gameTaskIcon from '@/assets/images/game/task_icon.png';
import gameCheckInModalIcon from '@/assets/images/game/checkIn_modal_icon.png';
import gameInviteIcon from '@/assets/images/game/invite_icon.png';
import gameAirdropIcon from '@/assets/images/game/airdrop_icon.png';
import gameSpinIcon from '@/assets/images/game/spin_icon.png';
import gameInviteRuleIcon from '@/assets/images/game/invite/rule_icon.svg';
import gameInviteRewardIcon1 from '@/assets/images/game/invite/reward_icon1.png';
import gameInviteRewardIcon2 from '@/assets/images/game/invite/reward_icon2.png';
import gameInvitePrizePoolIcon from '@/assets/images/game/invite/prize_pool_icon.png';
import gameInvitePrizePoolForwardIcon from '@/assets/images/game/invite/prize_pool_forward_icon.png';
import gameInviteTonIcon from '@/assets/images/game/invite/ton_icon.png';
import gameInviteEnergyIcon from '@/assets/images/game/invite/energy_icon.png';
import gameInviteViewMoreIcon from '@/assets/images/game/invite/view_more_icon.png';
import gameInviteRulesTitle from '@/assets/images/game/invite/rules_title.png';
import gameInviteRulesAttention from '@/assets/images/game/invite/rules_attention.png';
import gameInviteRulesTable from '@/assets/images/game/invite/rules_table.png';
import gameLeaderboardBanner from '@/assets/images/game/leaderboard/banner.png';
import gameLeaderboardRankOther from '@/assets/images/game/leaderboard/rank_other_icon.png';
import gameLeaderboardRank1 from '@/assets/images/game/leaderboard/rank1_icon.png';
import gameLeaderboardRank2 from '@/assets/images/game/leaderboard/rank2_icon.png';
import gameLeaderboardRank3 from '@/assets/images/game/leaderboard/rank3_icon.png';
import gameLevelIcon from '@/assets/images/game/level_icon.png';
import gameUpgradeBtn from '@/assets/images/game/upgrade_btn.png';
import gameUpgradeA from '@/assets/images/game/upgrade/upgrade_A.png';
import gameUpgradeB from '@/assets/images/game/upgrade/upgrade_B.png';
import gameUpgradeArrow from '@/assets/images/game/upgrade/upgrade_arrow.png';
import gameUpgradeCongra from '@/assets/images/game/upgrade/upgrade_congra.png';
import gameUpgradeVector from '@/assets/images/game/upgrade/vector.png';
import gameUpgradeLevelGreen from '@/assets/images/game/upgrade/upgrade_level_green.png';
import gameArrow from '@/assets/images/game/upgrade/arrow.png';
import gameUpgradeBanner from '@/assets/images/game/upgrade/upgrade_banner.png';
import gameUpgradeLevel from '@/assets/images/game/upgrade/upgrade_level.png';
import gameUpgradeModalIcon from '@/assets/images/game/upgrade/upgrade_modal_icon.png';
import gameSpinBg from '@/assets/images/game/spin/bg.png';
import gameSpinBg1 from '@/assets/images/game/spin/bg1.png';
import gameSpinBg2 from '@/assets/images/game/spin/bg2.png';
import gameSpinBg3 from '@/assets/images/game/spin/bg3.png';
import gameSpinBeerIcon from '@/assets/images/game/spin/beer_icon.png';
import gameSpinBetIcon from '@/assets/images/game/spin/bet_icon.png';
import gameSpinShiroIcon from '@/assets/images/game/spin/shiro_icon.png';
import gameSpinStarCoinIcon from '@/assets/images/game/spin/star_coin_icon.png';
import gameSpinBtn from '@/assets/images/game/spin/spin_btn.png';
import gameSpinChestIcon from '@/assets/images/game/spin/chest_icon.png';
import gameSpinCoinIcon from '@/assets/images/game/spin/coin_icon.png';
import gameSpinCrystalIcon from '@/assets/images/game/spin/crystal_icon.png';
import gameSpinEnergyIcon from '@/assets/images/game/spin/energy_icon.png';
import gameSpinHandle from '@/assets/images/game/spin/handle.png';
import gameSpinHandle2 from '@/assets/images/game/spin/handle2.png';
import gameSpinHoles from '@/assets/images/game/spin/holes.png';
import gameSpinArrowLeftIcon from '@/assets/images/game/spin/arrow_left_icon.png';
import gameSpinArrowRightIcon from '@/assets/images/game/spin/arrow_right_icon.png';
import gameSpinLightLeftIcon from '@/assets/images/game/spin/light_left_icon.png';
import gameSpinLightRightIcon from '@/assets/images/game/spin/light_right_icon.png';
import gameSpinPedestalFront from '@/assets/images/game/spin/pedestal_front.png';
import gameSpinPedestal from '@/assets/images/game/spin/pedestal.png';
import gameSpinPlantLeft from '@/assets/images/game/spin/plant_left.png';
import gameSpinPlantRight from '@/assets/images/game/spin/plant_right.png';
import gameSpinZooIcon from '@/assets/images/game/spin/zoo_icon.png';
import gameSpinRewardsTop from '@/assets/images/game/spin/rewards_top.png';
import gameSpinRewardsRect1 from '@/assets/images/game/spin/rewards_rect1.png';
import gameSpinRewardsRect2 from '@/assets/images/game/spin/rewards_rect2.png';
import gameSpinBtnPressed from '@/assets/images/game/spin/spin_btn_pressed.png';
import gameSpinBetPressedIcon from '@/assets/images/game/spin/bet_pressed_icon.png';
import guideJoinCommunityAddressIcon from '@/assets/images/guide/address_icon.svg';
import guideCommunityLinkIcon from '@/assets/images/guide/community_link_icon.png';
import guideCommunityTelegramIcon from '@/assets/images/guide/community_telegram_icon.png';
import guideCommunityXIcon from '@/assets/images/guide/community_x_icon.png';
import guideJoinCommunityDiscordIcon from '@/assets/images/guide/discord_icon.svg';
import pageLoadingBg from '@/assets/images/pageLoading/bg.webp';
import pageLoadingShiro from '@/assets/images/pageLoading/shiro.png';
import pageLoadingTitle from '@/assets/images/pageLoading/title.png';
import pageLoadingCoin from '@/assets/images/pageLoading/coin.png';
import pageLoadingTonIcon from '@/assets/images/pageLoading/ton_icon.png';
import pageLoadingBinanceIcon from '@/assets/images/pageLoading/binance_icon.png';
import pageLoadingAnimocaIcon from '@/assets/images/pageLoading/animoca_icon.png';
import pageLoadingGoldIcon from '@/assets/images/pageLoading/gold_icon.png';
import pageLoadingZooIcon from '@/assets/images/pageLoading/zoo_icon.png';
import pageLoadingFlowers from '@/assets/images/pageLoading/flowers.png';
import guideJoinCommunityRedditIcon from '@/assets/images/guide/reddit_icon.svg';
import guideJoinCommunityXIcon from '@/assets/images/guide/x_icon.svg';
import mineClaimIcon from '@/assets/images/mine/claim_icon.png';
import mineClaimSuccessIcon from '@/assets/images/mine/claim_success.png';
import mineLinkSuccess from '@/assets/images/mine/link_success.png';
import mineLinkIcon from '@/assets/images/mine/link.png';
import mineTaskIcon from '@/assets/images/mine/task.png';
import mineTimeIcon from '@/assets/images/mine/time_icon.svg';
import tabbarBg from '@/assets/images/tabbar/bg.png';
import tabbarGameSelectedIcon from '@/assets/images/tabbar/tabbar_game_selected.png';
import tabbarGameIcon from '@/assets/images/tabbar/tabbar_game.png';
import tabbarMineSelectedIcon from '@/assets/images/tabbar/tabbar_mine_selected.png';
import tabbarMineIcon from '@/assets/images/tabbar/tabbar_mine.png';
import tabbarEarnIcon from '@/assets/images/tabbar/tabbar_earn.png';
import tabbarEarnSelectedIcon from '@/assets/images/tabbar/tabbar_earn_selected.png';
import tabbarEarnDotIcon from '@/assets/images/tabbar/tabbar_earn_dot.png';
import tabbarTaskSelectedIcon from '@/assets/images/tabbar/tabbar_task_selected.png';
import tabbarTaskIcon from '@/assets/images/tabbar/tabbar_task.png';
import tabbarSpinIcon from '@/assets/images/tabbar/tabbar_spin.png';
import tabbarSpinSelectedIcon from '@/assets/images/tabbar/tabbar_spin_selected.png';
import tabbarDrawIcon from '@/assets/images/tabbar/tabbar_draw.png';
import profileZooIcon from '@/assets/images/profile/zoo_icon.png';
import profileCrystalIcon from '@/assets/images/profile/crystal_icon.png';
import profileTonIcon from '@/assets/images/profile/ton_icon.png';
import profileCoinIcon from '@/assets/images/profile/coin_icon.png';
import profileCopyIcon from '@/assets/images/profile/copy_icon.svg';
import profileTabiIcon from '@/assets/images/profile/tabi.png';
import profileInfoIcon from '@/assets/images/profile/info_icon.svg';
import profileSettingIcon from '@/assets/images/profile/setting.png';
import profileServiceIcon from '@/assets/images/profile/service.png';
import profilePrivacyIcon from '@/assets/images/profile/privacy.png';
import profileGoIcon from '@/assets/images/profile/go.png';
import profileMarketplace from '@/assets/images/profile/marketplace.png';
import profileWalletIcon from '@/assets/images/profile/wallet.svg';
import profileMarketIcon from '@/assets/images/profile/market.svg';
import taskBanner from '@/assets/images/task/banner.png';
import bg2 from '@/assets/images/task/bg_2.png';
import taskLinkBtn from '@/assets/images/task/link_btn.png';
import modalIcon1 from '@/assets/images/task/modal_icon_1.png';
import taskModalIcon from '@/assets/images/task/modal_icon.png';
import taskModalIconPurple from '@/assets/images/task/modal_icon_purple.png';
import taskAirdrop from '@/assets/images/task/airdrop.png';
import taskCompletedIcon from '@/assets/images/task/task_completed_icon.png';
import tv from '@/assets/images/common/tv.png';
import empty from '@/assets/images/mine/empty.png';
import tonOverviewShiro from '@/assets/images/tonOverview/shiro.png';
import tonOverviewTonIcon from '@/assets/images/tonOverview/ton_icon.png';
import tonOverviewNoTonRecord from '@/assets/images/tonOverview/no_ton_record.png';
import tonOverviewWalletIcon from '@/assets/images/tonOverview/wallet_icon.png';
import tonOverviewWarnIcon from '@/assets/images/tonOverview/warn_icon.png';
import tonCashOutConfirmModalIcon from '@/assets/images/tonOverview/cashOutConfirmModal_icon.png';
import defaultImage from '@/assets/images/common/default_image.png';
import tiktokIcon from '@/assets/images/login/tiktok.svg';
import tkIcon from '@/assets/images/login/tk.png';
import telegramIcon from '@/assets/images/login/telegram.svg';
import snapchatIcon from '@/assets/images/login/snapchat.svg';
import snapIcon from '@/assets/images/login/snap.svg';
import tabiPartyBg from '@/assets/images/tabiParty/bg.jpg';
import tabiPartyTelegram from '@/assets/images/tabiParty/telegram.svg';
import tabiPartyTwitter from '@/assets/images/tabiParty/twitter.svg';
import tabiPartyTip from '@/assets/images/tabiParty/tip.svg';
import tabiPartyMore from '@/assets/images/tabiParty/more.svg';
import drawFreeIcon from '@/assets/images/mininode/free.png';
import drawOrdinaryIcon from '@/assets/images/mininode/ordinary.png';
import drawAdvancedIcon from '@/assets/images/mininode/advanced.png';
import drawHigherIcon from '@/assets/images/mininode/higher.png';
import drawHigherBlueIcon from '@/assets/images/mininode/higher_blue.png';
import drawShiro from '@/assets/images/mininode/shiro.png';
import drawCoinStar from '@/assets/images/mininode/coin_star.png';
import drawCoinMoreStar from '@/assets/images/mininode/more_star.png';
import drawCoinMoreStarReward from '@/assets/images/mininode/more_star_reward.png';
import drawCoinTask from '@/assets/images/mininode/task.png';
import drawStar from '@/assets/images/mininode/star.png';
import drawBag from '@/assets/images/draw/bag.png';
import drawCasting from '@/assets/images/draw/casting.png';
import drawTabiPool from '@/assets/images/draw/tabipool.png';
import drawTabiPoolFire from '@/assets/images/draw/fire.png';
import drawTabiPoolShiro from '@/assets/images/draw/arena_shiro.png';
import drawTabiPoolTabi from '@/assets/images/draw/tabi_coin.png';
import drawTabiPoolMoreTabi from '@/assets/images/draw/more_tabi.png';
import drawTabiPoolPrepared from '@/assets/images/draw/prepared.png';
import drawTabiPoolQueen from '@/assets/images/draw/queen.png';
import drawTabiPoolBg1 from '@/assets/images/draw/bg1.svg';
import drawIncrease from '@/assets/images/draw/increase.png';
import drawDecrease from '@/assets/images/draw/decrease.png';
import drawDecreaseDone from '@/assets/images/draw/decrease_done.png';
import drawCatchFree from '@/assets/images/draw/catch/free.png';
import drawCatchDraw from '@/assets/images/draw/catch/draw.png';
import drawCatchSenior from '@/assets/images/draw/catch/senior.png';
import drawCatchFreeBasic from '@/assets/images/draw/catch/free_basic.png';
import drawCatchDrawBasic from '@/assets/images/draw/catch/draw_basic.png';
import drawCatchSeniorBasic from '@/assets/images/draw/catch/senior_basic.png';
import drawCatchBtn from '@/assets/images/draw/catch/btn.png';
import drawDown from '@/assets/images/draw/down.png';
import drawCatchReward from '@/assets/images/draw/catch/reward.png';
import drawCatchStar from '@/assets/images/draw/catch/star.png';
import drawCatchGuide from '@/assets/images/draw/catch/guide.png';
import drawCatchGuideBg from '@/assets/images/draw/catch/guide_bg.png';
import drawCatchGift from '@/assets/images/draw/catch/gift.png';
import drawCatchHook1 from '@/assets/images/draw/catch/hook1.png';
import drawCatchHook2 from '@/assets/images/draw/catch/hook2.png';
import drawCatchHook3 from '@/assets/images/draw/catch/hook3.png';
import drawCatchRewardIncrease from '@/assets/images/draw/catch/reward_increase.png';
import drawCatchRewardGift from '@/assets/images/draw/catch/reward_gift.png';
import drawCatchRewardStar from '@/assets/images/draw/catch/reward_star.png';
import drawCatchDrawBg1 from '@/assets/images/draw/catch/draw_bg1.png';
import drawCatchDrawBg2 from '@/assets/images/draw/catch/draw_bg2.png';
import drawCatchRewardMax from '@/assets/images/draw/catch/reward_max.png';
import drawLight from '@/assets/images/draw/light.png';
import drawStreamers from '@/assets/images/draw/streamers.png';
import drawTicket from '@/assets/images/draw/ticket.png';
import drawTicketIcon from '@/assets/images/draw/ticket_icon.png';
import drawTicketInfo from '@/assets/images/draw/ticket_info.png';
import drawGreen1 from '@/assets/images/draw/green1.svg';
import drawGreen2 from '@/assets/images/draw/green2.svg';
import starBanner from '@/assets/images/star/banner.png';
import starDetail from '@/assets/images/star/detail.png';
import starCoin from '@/assets/images/star/coin.png';
import starRecharge from '@/assets/images/star/recharge.png';
import logoutIcon from '@/assets/images/profile/logout.svg';
import checkIcon from '@/assets/images/login/check.svg';
import moreBall from '@/assets/images/more/ball.png';
import moreJerry from '@/assets/images/more/jerry.png';
import moreStar from '@/assets/images/more/star.png';
import moreStarCoin from '@/assets/images/more/star_coin.png';
import miniNodeShopBanner from '@/assets/images/mininode/shop/banner.png';
import miniNodeShopArrow from '@/assets/images/mininode/shop/arraw.svg';
import miniNodeShopInvite from '@/assets/images/mininode/shop/invitation.png';
import miniNodePlus from '@/assets/images/mininode/shop/plus.png';
import miniNodeMinus from '@/assets/images/mininode/shop/minus.png';
import miniNodeOff from '@/assets/images/mininode/shop/off.svg';
import miniNodeShop from '@/assets/images/mininode/shop/shop.png';

export default {
  common: {
    symbol,
    coin,
    coin2,
    coin3,
    success,
    error,
    close,
    checkedIcon,
    tv,
    volumeControl,
    volume,
    defaultImage,
    comingSoon,
  },
  tabbar: {
    bg: tabbarBg,
    gameIcon: tabbarGameIcon,
    gameSelectedIcon: tabbarGameSelectedIcon,
    mineIcon: tabbarMineIcon,
    mineSelectedIcon: tabbarMineSelectedIcon,
    earnIcon: tabbarEarnIcon,
    earnSelectedIcon: tabbarEarnSelectedIcon,
    earnDotIcon: tabbarEarnDotIcon,
    taskIcon: tabbarTaskIcon,
    taskSelectedIcon: tabbarTaskSelectedIcon,
    spinIcon: tabbarSpinIcon,
    spinSelectedIcon: tabbarSpinSelectedIcon,
    drawIcon: tabbarDrawIcon,
  },
  header: {
    zooIcon: headerZooIcon,
    crystalIcon: headerCrystalIcon,
    coinIcon: headerCoinIcon,
    assetsIcon: headerAssetsIcon,
    starIcon: headerStarIcon,
  },
  profile: {
    zooIcon: profileZooIcon,
    crystalIcon: profileCrystalIcon,
    tonIcon: profileTonIcon,
    coinIcon: profileCoinIcon,
    copyIcon: profileCopyIcon,
    tabiIcon: profileTabiIcon,
    infoIcon: profileInfoIcon,
    settingIcon: profileSettingIcon,
    serviceIcon: profileServiceIcon,
    privacyIcon: profilePrivacyIcon,
    goIcon: profileGoIcon,
    walletIcon: profileWalletIcon,
    marketplace: profileMarketplace,
    market: profileMarketIcon,
    logoutIcon,
  },
  pageLoading: {
    bg: pageLoadingBg,
    shiro: pageLoadingShiro,
    title: pageLoadingTitle,
    coin: pageLoadingCoin,
    tonIcon: pageLoadingTonIcon,
    binanceIcon: pageLoadingBinanceIcon,
    animocaIcon: pageLoadingAnimocaIcon,
    goldIcon: pageLoadingGoldIcon,
    zooIcon: pageLoadingZooIcon,
    flowers: pageLoadingFlowers,
  },
  guide: {
    joinCommunity: {
      xIcon: guideJoinCommunityXIcon,
      discordIcon: guideJoinCommunityDiscordIcon,
      addressIcon: guideJoinCommunityAddressIcon,
      redditIcon: guideJoinCommunityRedditIcon,
      communityTelegramIcon: guideCommunityTelegramIcon,
      communityLinkIcon: guideCommunityLinkIcon,
      communityXIcon: guideCommunityXIcon,
    },
  },
  game: {
    avatarDefault: gameAvatarDefault,
    levelIcon: gameLevelIcon,
    upgradeBtn: gameUpgradeBtn,
    inviteIcon: gameInviteIcon,
    airdropIcon: gameAirdropIcon,
    airdrop: gameAirDropIcon,
    taskIcon: gameTaskIcon,
    tabiParty: gameTabiPartyIcon,
    spinIcon: gameSpinIcon,
    checkInIcon: gameCheckInIcon,
    upgradeBanner: gameUpgradeBanner,
    upgradeModalIcon: gameUpgradeModalIcon,
    upgradeLevel: gameUpgradeLevel,
    upgradeLevelGreen: gameUpgradeLevelGreen,
    vector: gameUpgradeVector,
    upgradeA: gameUpgradeA,
    upgradeB: gameUpgradeB,
    upgradeArrow: gameUpgradeArrow,
    arrow: gameArrow,
    upgradeCongra: gameUpgradeCongra,
    checkInModalIcon: gameCheckInModalIcon,
    levelOldIcon: gameLevelOld,
    levelNewIcon: gameLevelNew,
    invite: {
      ruleIcon: gameInviteRuleIcon,
      rewardIcon1: gameInviteRewardIcon1,
      rewardIcon2: gameInviteRewardIcon2,
      prizePoolIcon: gameInvitePrizePoolIcon,
      prizePoolForwardIcon: gameInvitePrizePoolForwardIcon,
      tonIcon: gameInviteTonIcon,
      energyIcon: gameInviteEnergyIcon,
      viewMoreIcon: gameInviteViewMoreIcon,
      rulesTitle: gameInviteRulesTitle,
      rulesAttention: gameInviteRulesAttention,
      rulesTable: gameInviteRulesTable,
    },
    leaderboard: {
      banner: gameLeaderboardBanner,
      rank1: gameLeaderboardRank1,
      rank2: gameLeaderboardRank2,
      rank3: gameLeaderboardRank3,
      rankOther: gameLeaderboardRankOther,
    },
    spin: {
      bg: gameSpinBg,
      bg1: gameSpinBg1,
      bg2: gameSpinBg2,
      bg3: gameSpinBg3,
      betIcon: gameSpinBetIcon,
      betPressedIcon: gameSpinBetPressedIcon,
      rewardsTop: gameSpinRewardsTop,
      rewardsRect1: gameSpinRewardsRect1,
      rewardsRect2: gameSpinRewardsRect2,
      handle: gameSpinHandle,
      handle2: gameSpinHandle2,
      holes: gameSpinHoles,
      lightLeftIcon: gameSpinLightLeftIcon,
      lightRightIcon: gameSpinLightRightIcon,
      pedestalFront: gameSpinPedestalFront,
      pedestal: gameSpinPedestal,
      plantLeft: gameSpinPlantLeft,
      plantRight: gameSpinPlantRight,
      spinBtn: gameSpinBtn,
      spinBtnPressed: gameSpinBtnPressed,
      starCoinIcon: gameSpinStarCoinIcon,
      arrowLeftIcon: gameSpinArrowLeftIcon,
      arrowRightIcon: gameSpinArrowRightIcon,
      coinIcon: gameSpinCoinIcon,
      energyIcon: gameSpinEnergyIcon,
      slot: [
        gameSpinBeerIcon,
        gameSpinChestIcon,
        gameSpinCoinIcon,
        gameSpinCrystalIcon,
        gameSpinEnergyIcon,
        gameSpinShiroIcon,
        gameSpinZooIcon,
      ],
    },
  },
  mine: {
    timeIcon: mineTimeIcon,
    linkIcon: mineLinkIcon,
    taskIcon: mineTaskIcon,
    mineClaimIcon,
    mineClaimSuccessIcon,
    mineLinkSuccess,
    empty,
  },
  task: {
    banner: taskBanner,
    linkBtn: taskLinkBtn,
    modalIcon: taskModalIcon,
    completedIcon: taskCompletedIcon,
    modalIconPurple: taskModalIconPurple,
    modalIcon1,
    bg2,
    taskAirdrop,
  },
  airdrop: {
    airdropBg,
    airdropBannerBg,
    copyIcon,
    TikTokIcon,
    airdropClose,
    airdropTip,
    airdropPolygon
  },
  tonOverview: {
    shiro: tonOverviewShiro,
    tonIcon: tonOverviewTonIcon,
    noTonRecord: tonOverviewNoTonRecord,
    walletIcon: tonOverviewWalletIcon,
    warnIcon: tonOverviewWarnIcon,
    confirmModalIcon: tonCashOutConfirmModalIcon,
  },
  login: {
    tiktokIcon,
    snapchatIcon,
    checkIcon,
    telegramIcon,
    tkIcon,
    snapIcon
  },

  tabiParty: {
    bg: tabiPartyBg,
    telegram: tabiPartyTelegram,
    twitter: tabiPartyTwitter,
    more: tabiPartyMore,
    tip: tabiPartyTip,
  },
  draw: {
    freeIcon: drawFreeIcon,
    ordinaryIcon: drawOrdinaryIcon,
    advancedIcon: drawAdvancedIcon,
    higherIcon: drawHigherIcon,
    higherBlueIcon: drawHigherBlueIcon,
    shiro: drawShiro,
    coinStar: drawCoinStar,
    moreStar: drawCoinMoreStar,
    moreStarReward: drawCoinMoreStarReward,
    moreStarTask: drawCoinTask,
    bag: drawBag,
    casting: drawCasting,
    tabiPool: drawTabiPool,
    fire: drawTabiPoolFire,
    queen: drawTabiPoolQueen,
    poolBg1: drawTabiPoolBg1,
    increase: drawIncrease,
    decrease: drawDecrease,
    drawDecreaseDone,
    starCoin: drawStar,
    drawGreen1,
    drawGreen2,
    drawTabiPoolShiro,
    drawTabiPoolTabi,
    drawTabiPoolMoreTabi,
    drawTabiPoolPrepared,
    light: drawLight,
    streamers: drawStreamers,
    ticket: drawTicket,
    ticketIcon: drawTicketIcon,
    ticketInfo: drawTicketInfo,
    catch: {
      free: drawCatchFree,
      draw: drawCatchDraw,
      senior: drawCatchSenior,
      freeBasic: drawCatchFreeBasic,
      drawBasic: drawCatchDrawBasic,
      seniorBasic: drawCatchSeniorBasic,
      reward: drawCatchReward,
      star: drawCatchStar,
      gift: drawCatchGift,
      guide: drawCatchGuide,
      guideBg: drawCatchGuideBg,
      hook1: drawCatchHook1,
      hook2: drawCatchHook2,
      hook3: drawCatchHook3,
      rewardIncrease: drawCatchRewardIncrease,
      rewardMax: drawCatchRewardMax,
      rewardGift: drawCatchRewardGift,
      rewardStar: drawCatchRewardStar,
      drawBg1: drawCatchDrawBg1,
      drawBg2: drawCatchDrawBg2,
      btn: drawCatchBtn,
      down: drawDown,
    },
  },
  star: {
    starBanner,
    starDetail,
    starCoin,
    starRecharge,
  },
  more: {
    moreBall,
    moreJerry,
    moreStar,
    moreStarCoin,
  },
  mininode: {
    miniNodeShopBanner,
    miniNodeShopArrow,
    miniNodeShopInvite,
    miniNodePlus,
    miniNodeMinus,
    miniNodeOff,
    miniNodeShop,
  },
};
