/*
 * Created Date: December 9th 2024, 3:09:25 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: January 6th 2025, 5:58:18 pm
 */
import { loginWithTG } from '@/api/login';
import Loading from '@/components/Loading';
import Toast, { ToastRef } from '@/components/Toast';
import images from '@/const/images';
import { LoginResult, setRawData } from '@/utils/mockRawData';
import { useRequest } from 'ahooks';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
export default function Login() {
  const isTK =
    navigator.userAgent.includes('musical_ly') ||
    location.pathname.includes('tiktok');

  const isSnap =
    navigator.userAgent.includes('Snapchat') ||
    location.pathname.includes('snapchat');

  const toastRef = useRef<ToastRef>();

  const [isAgree, setIsAgree] = useState(false);

  const { runAsync, loading } = useRequest(
    async (value) => {
      try {
        const res = await loginWithTG(value);
        setRawData(res.rawdata);
        location.href = `${import.meta.env.VITE_TG_URL}`;
      } catch (error) {
        toastRef.current?.show('error', 'Login failed');
      }
    },
    { manual: true }
  );

  const btn = useMemo(() => {
    if (isTK) {
      return (
        <div
          className="flex h-11 w-[291px] cursor-pointer font-changa-one justify-center items-center gap-1.5 [background:#FFF] shadow-[0px_0.4px_1px_0px_rgba(0,0,0,0.34),0px_-2.8px_0px_1px_rgba(0,0,0,0.08)_inset] px-0 py-3.5 rounded-[47px] text-black text-center text-base font-normal leading-4 capitalize"
          onClick={() => {
            if (!isAgree) {
              toastRef.current?.show(
                'error',
                'Agree to the Terms of Service and Privacy Policy'
              );
              return;
            }

            location.href = `${import.meta.env.VITE_BASE_URL}/tiktok/oauth/authorize`;
          }}
        >
          <img src={images.login.tiktokIcon} alt="" className="w-5 h-5" />
          Login With TikTok
        </div>
      );
    }

    if (isSnap) {
      return (
        <div
          className="flex h-11 w-[291px] cursor-pointer font-changa-one justify-center items-center gap-1.5 [background:#FFF] shadow-[0px_0.4px_1px_0px_rgba(0,0,0,0.34),0px_-2.8px_0px_1px_rgba(0,0,0,0.08)_inset] px-0 py-3.5 rounded-[47px] text-black text-center text-base font-normal leading-4 capitalize"
          onClick={() => {
            if (!isAgree) {
              toastRef.current?.show(
                'error',
                'Agree to the Terms of Service and Privacy Policy'
              );
              return;
            }

            location.href = `${import.meta.env.VITE_BASE_URL}/snap/oauth/authorize`;
          }}
        >
          <img src={images.login.snapchatIcon} alt="" className="w-5 h-5" />
          Login With Snapchat
        </div>
      );
    }

    return (
      <div
        className="flex h-11 w-[291px] cursor-pointer font-changa-one justify-center items-center gap-1.5 [background:#FFF] shadow-[0px_0.4px_1px_0px_rgba(0,0,0,0.34),0px_-2.8px_0px_1px_rgba(0,0,0,0.08)_inset] px-0 py-3.5 rounded-[47px] text-black text-center text-base font-normal leading-4 capitalize"
        onClick={() => {
          if (!isAgree) {
            toastRef.current?.show(
              'error',
              'Agree to the Terms of Service and Privacy Policy'
            );
            return;
          }
          (window as any).Telegram.Login.auth(
            {
              bot_id: import.meta.env.VITE_TG_BOT_ID,
              request_access: 'write',
              embed: 1,
            },
            async (data: LoginResult) => {
              try {
                const stringData = {
                  ...data,
                  id: String(data.id),
                  auth_date: String(data.auth_date),
                };
                await runAsync(stringData);
              } catch (error) {
                toastRef.current?.show('error', 'Login failed');
              }
            }
          );
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <img src={images.login.telegramIcon} alt="" className="w-5 h-5" />
            Login With Telegram
          </>
        )}
      </div>
    );
  }, [isTK, isAgree, isSnap]);

  return (
    <div
      className="fixed w-[375px] h-screen z-[105] flex justify-center items-center left-1/2 -translate-x-1/2 top-0 bg-white bg-top bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${images.pageLoading.bg})` }}
    >
      <div className="absolute top-[348px] text-center z-8">
        <div className="w-[205px] h-[72px] bg-[url('@/assets/images/pageLoading/title.png')] bg-contain pt-[45px] mx-auto mb-[24px]">
          <span className="pageLoading-text text-[16px] font-changa-one">
            {import.meta.env?.VITE_VERSION}
          </span>
        </div>

        <div className="w-[335px] flex gap-4 flex-col items-center">
          {btn}
          <div
            className="w-[288px] flex gap-[6px]"
            onClick={() => {
              setIsAgree(!isAgree);
            }}
          >
            <div
              className={clsx(
                'mt-[1.5px]',
                isAgree
                  ? 'w-4 h-4 bg-white rounded-full flex items-center justify-center shrink-0'
                  : 'w-4 h-4 border-white border-[1.2px] rounded-full shrink-0'
              )}
            >
              {isAgree && (
                <img
                  src={images.login.checkIcon}
                  alt=""
                  width={16}
                  height={16}
                  className="w-4 h-4"
                />
              )}
            </div>
            <div className="text-white font-poppins text-[11px] font-normal leading-[normal] text-left">
              Agree to the{' '}
              <Link
                to="https://app.tabizoo.com/TermsOfService.html"
                target="_blank"
                className="underline decoration-solid [text-decoration-skip-ink:auto] decoration-auto underline-offset-auto [text-underline-position:from-font]"
                onClick={(e) => e.stopPropagation()}
              >
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link
                to="https://app.tabizoo.com/OfficialPrivacyPolicy.html"
                target="_blank"
                className="underline decoration-solid [text-decoration-skip-ink:auto] decoration-auto underline-offset-auto [text-underline-position:from-font]"
                onClick={(e) => e.stopPropagation()}
              >
                Privacy Policy
              </Link>
            </div>
          </div>
          {!isTK && !isSnap && (
            <>
              <div className="flex items-center font-poppins gap-[8px] text-xs font-normal leading-4 opacity-50 text-white">
                <span className="border-white border-solid border w-[129px] opacity-35"></span>
                OR
                <span className="border-white border-solid border w-[129px] opacity-35"></span>
              </div>
              <div className="flex items-center gap-[20px]">
                <div
                  onClick={() => {
                    if (!isAgree) {
                      toastRef.current?.show(
                        'error',
                        'Agree to the Terms of Service and Privacy Policy'
                      );
                      return;
                    }

                    location.href = `${import.meta.env.VITE_BASE_URL}/tiktok/oauth/authorize`;
                  }}
                  className="flex justify-center cursor-pointer items-center gap-1.5 [background:#000] shadow-[0px_0.4px_1px_0px_rgba(0,0,0,0.34),0px_-2px_0px_1px_rgba(0,0,0,0.08)_inset] p-[6px] rounded-[47px]"
                >
                  <img
                    src={images.login.tkIcon}
                    alt=""
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <div
                  onClick={() => {
                    if (!isAgree) {
                      toastRef.current?.show(
                        'error',
                        'Agree to the Terms of Service and Privacy Policy'
                      );
                      return;
                    }

                    location.href = `${import.meta.env.VITE_BASE_URL}/snap/oauth/authorize`;
                  }}
                  className="flex justify-center items-center gap-1.5 [background:#FFF] shadow-[0px_0.4px_1px_0px_rgba(0,0,0,0.34),0px_-2px_0px_1px_rgba(0,0,0,0.08)_inset] p-[6px] rounded-[47px] cursor-pointer"
                >
                  <img
                    src={images.login.snapIcon}
                    alt=""
                    className="w-[20px] h-[20px]"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="absolute bottom-[16px] left-1/2 -translate-x-1/2 w-fit text-black text-[10px] font-normal leading-[normal] opacity-50">
        © TABI {dayjs().format('YYYY')} All Rights Reserved{' '}
      </div>

      <Toast ref={toastRef} />
    </div>
  );
}
