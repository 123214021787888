import { ILevelConfigList, IUserProfile } from '@/api/game';
import BasicLayout from '@/layout/basicLayout';
import TabbarLayout from '@/layout/tabbarLayout';
import ErrorBoundary from '@/components/ErrorBoundary';
import Index from '@/pages/Index';
import Spin from '@/pages/Spin';
import Upgrading from '@/pages/Upgrading';
import Draw from '@/pages/Draw';
import { createBrowserRouter } from 'react-router-dom';
import lazyRouters from './lazyRouters';
import Login from '@/pages/Login';
import Callback from '@/pages/Callback';
import { IDrawConfig, IMiniNodeConfig, IOwnedRewards } from '@/api/draw';
import MoreMiniNode from '@/pages/MoreMiniNode';
import { IRangePointsConfig } from '@/api/mininode';

export interface IRouterRootLoaderData {
  userProfile: IUserProfile | undefined;
  levelConfigs: ILevelConfigList | undefined;
  drawConfig: IDrawConfig | undefined;
  ownedRewards: IOwnedRewards | undefined;
  synthesisMaterials: IMiniNodeConfig | undefined;
  rangePoints: IRangePointsConfig[] | undefined;
  isH5: boolean;
}

const router = createBrowserRouter([
  {
    path: '/',
    id: 'root',
    element: <BasicLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <TabbarLayout />,
        children: [
          {
            index: true,
            path: 'index',
            element: <Index />,
          },
          {
            path: 'draw',
            element: <Draw />,
          },
          {
            path: 'spin',
            element: <Spin />,
          },
          {
            path: 'tiktok',
            element: <Index />,
          },
          {
            path: 'snapchat',
            element: <Index />,
          },
        ],
      },
      ...lazyRouters,
      {
        path: '/upgrading',
        element: <Upgrading />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'tiktok/login',
        element: <Login />,
      },
      {
        path: 'snapchat/login',
        element: <Login />,
      },
      {
        path: 'moreMiniNode',
        element: <MoreMiniNode />,
      },
    ],
  },
  {
    path: '/snap/callback',
    element: <Callback />,
  },
  {
    path: '/tiktok/callback',
    element: <Callback />,
  },
  {
    path: '/ins/callback',
    element: <Callback />,
  },
  {
    path: '/telegram/callback',
    element: <Callback />,
  },
  {
    path: '*',
    element: <ErrorBoundary errorMsg="404 Not Found" />,
  },
]);

export default router;
