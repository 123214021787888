import router from '@/router/index';
import { SDKProvider } from '@telegram-apps/sdk-react';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { useMount } from 'ahooks';
import { postEvent } from '@telegram-apps/sdk-react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { RouterProvider } from 'react-router-dom';
import PageLoading from './components/PageLoading';
// import { ThirdwebProvider } from 'thirdweb/react';
// import { TonAdInit } from 'ton-ai-sdk';

export default function App() {
  useMount(() => {
    postEvent('web_app_setup_swipe_behavior', { allow_vertical_swipe: false });
    postEvent('web_app_expand');
  });

  // useMount(() => {
  //   const debugOptions = import.meta.env.DEV ? { debug: true } : {};
  //   TonAdInit({
  //     appId: import.meta.env.VITE_TON_APP_ID,
  //     ...debugOptions,
  //   });
  // });

  return (
    <TonConnectUIProvider
      actionsConfiguration={{
        twaReturnUrl: import.meta.env.VITE_TWA_RETURN_URL,
      }}
      walletsListConfiguration={{
        includeWallets: [
          {
            appName: 'tonwallet',
            name: 'TON Wallet',
            imageUrl: 'https://wallet.ton.org/assets/ui/qr-logo.png',
            aboutUrl:
              'https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd',
            universalLink: 'https://wallet.ton.org/ton-connect',
            jsBridgeKey: 'tonwallet',
            bridgeUrl: 'https://bridge.tonapi.io/bridge',
            platforms: ['chrome', 'safari', 'ios', 'android'],
          },
          {
            appName: 'bitgetWalletLite',
            name: 'Bitget Wallet Lite',
            imageUrl:
              'https://raw.githubusercontent.com/bitgetwallet/download/main/logo/png/bitget_wallet_lite_logo.png',
            aboutUrl: 'https://web3.bitget.com',
            universalLink: 'https://t.me/BitgetWallet_TGBot?attach=wallet',
            bridgeUrl: 'https://ton-connect-bridge.bgwapi.io/bridge',
            platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
          },
          {
            name: 'Bitget Wallet',
            appName: 'bitgetTonWallet',
            jsBridgeKey: 'bitgetTonWallet',
            imageUrl:
              'https://raw.githubusercontent.com/bitkeepwallet/download/main/logo/png/bitget%20wallet_logo_iOS.png',
            aboutUrl: 'https://web3.bitget.com',
            bridgeUrl: 'https://ton-connect-bridge.bgwapi.io/bridge',
            universalLink: 'https://bkcode.vip/ton-connect',
            platforms: ['ios', 'android', 'chrome'],
          },
        ],
      }}
      manifestUrl={import.meta.env.VITE_TON_MANIFEST_URL}
    >
      <SDKProvider acceptCustomStyles>
        <AppRoot>
          <RouterProvider router={router} fallbackElement={<PageLoading />} />
        </AppRoot>
      </SDKProvider>
    </TonConnectUIProvider>
  );
}
