import request, { api, BaseResponse } from '.';
import ky from 'ky';

export enum Platform {
  TELEGRAM = 'tg',
  TIK_TOK = 'tiktok',
  SNAP_CHAT = 'snapchat',
}

export interface ISignInInfo {
  user: {
    id: number;
    app_user_id: number;
    name: string;
    photo_url: string;
    is_premium: number;
    level: number;
    invites: number;
    referral: number;
    coins: number;
    tabi_address: string;
    ton_address: string;
    streak: number;
    create_time: string;
    login_time: string;
    check_in_date: string;
    on_board: number;
    airdrop_power: number;
  };
  lastLoginTime: string;
  invitationRewardSinceLastLogin: number;
  invitation_code: string;
}

export enum SysStatusEnum {
  Close = 1,
  Open = 2,
}

export interface ISysStatus {
  status: SysStatusEnum;
  msg: string;
  list: number[];
}

export interface IEnergy {
  energy: number;
  energy_next_updated: string;
}

export interface IUserProfile {
  user: {
    id: number;
    tg_user_id: string;
    app_user_id: string;
    third_platform_id: string
    name: string;
    photo_url: string;
    is_premium: number;
    level: number;
    invites: number;
    referral: number;
    coins: number;
    zoo_coins: number;
    crystal_coins: number;
    tabi_address: string;
    ton_address: string;
    streak: number;
    create_time: string;
    login_time: string;
    check_in_date: string;
    on_board: number;
    ton_balance: number;
    energy: IEnergy;
    secondary_level: number;
    orign_level: number;
    star: number;
    ve_tabi: number;
    airdrop_power: number;
    platform: Platform,
    platform_user_info: {
      user: {
        avatar_url: string;
        display_name: string;
        open_id: string;
        union_id: string;
      };
    };
    activity_invitation_reward?: {
      has_reward: boolean;
      invitation_code: string;
      reward_type: string;
      reward_amount: number;
    };
  };
}

export interface IMiningInfo {
  mining_data: {
    app_user_id: number;
    rate: number;
    referral_rate: number;
    top_limit: number;
    accumulated: number;
    next_claim_time: string;
    refresh_time: string;
    current: number;
    next_claim_timestamp: number;
    refresh_timestamp: number;
  };
}

export interface ILevelConfig {
  id: number;
  major_level: number;
  secondary_level: number;
  zoo_rate: number;
  daily_zoo_gen: number;
  level_up_coin: number;
  insert_tm: string;
  update_tm: string;
}

export interface ILevelConfigList {
  user: ILevelConfig[];
}

export interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface Pageable {
  pageNumber: number;
  pageSize: number;
  sort: Sort;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface ISpinInfo {
  energy: IEnergy;
  activity_invitation_reward?: {
    has_reward: boolean;
    invitation_code: string;
    reward_type: string;
    reward_amount: number;
  };
}

export enum MultiplierTypeEnum {
  One = 1,
  Two,
  Three,
}

export interface ISpinPlayRequest {
  multiplier: MultiplierTypeEnum; // 倍数 1 ~ 3
}

// 无能量时返回null
export interface ISpinPlayRecord {
  energy: IEnergy;
  prize: {
    rule_id: number;
    prize_type: string;
    amount: number;
    multiplier: MultiplierTypeEnum;
  };
}

export interface IWatchADStatus {
  date: string;
  count: number;
  current_ad_provider_id: ADProvider;
}

export enum ADProvider {
  TON_AI = 'tonai',
  AD_STAR = 'adstar',
  AD_SGRAM = 'adsgram',
}
export interface ISpinReport {
  ad_block_id: string;
  ad_provider: ADProvider;
  campaign_id: string;
}

export const signin = async (params: { invitation_code: string }) => {
  const res: ISignInInfo = await request('post', 'user/v1/sign-in', {
    json: params,
  });
  return res;
};

export const getSysStatus = async () => {
  const res: ISysStatus = await ky
    .get(`${import.meta.env.VITE_UPGRADE_BASE_URL}/sys/status`)
    .json();
  return res;
};

export const getUserProfile = async () => {
  const res: IUserProfile = await request('get', 'user/v1/profile');
  return res;
};

export const getMiningInfo = async () => {
  const res: IMiningInfo = await request('get', 'mining/v1/info');
  return res;
};

export const claim = async () => {
  const res: { zoo_coins: number } = await request('post', 'mining/v1/claim');
  return res;
};

export const checkIn = async () => {
  const res = await request('post', 'user/v1/check-in');
  return res;
};

export const levelUp = async () => {
  const res = await request('post', 'user/v1/level-up');
  return res;
};

export const spinInfo = async () => {
  const res: ISpinInfo = await request('post', 'spin/v1/info');
  return res;
};

export const spinPlay = async (params: ISpinPlayRequest) => {
  const res: ISpinPlayRecord = await request('post', 'spin/v1/play', {
    json: params,
  });
  return res;
};

export const spinReport = async (params: ISpinReport) => {
  const res = await request('post', 'ads/v1/spin_report', {
    json: params,
  });
  return res;
};

export const getLevelConfig = async () => {
  const res: ILevelConfigList = await request('get', 'user/v1/level-config');
  return res;
};

export const getSpinWatchADStatus = async () => {
  const res: IWatchADStatus = await request('get', 'ads/v1/spin_ads_status');
  return res;
};

export enum CheckInStatus {
  Fail = 2,
  Success = 1,
  NoWatch = 3,
}

export const checkInAD = async (checkInStatus: CheckInStatus) => {
  const res = await request<{
    check_in_status: CheckInStatus;
    check_in_reward: number;
  }>('post', 'user/v1/check-in-ad', {
    json: {
      check_in_status: checkInStatus,
    },
  });

  return res;
};

export enum ReportStatus {
  Done = 1,
  Tap = 3,
}

export const reportAD = async (params: {
  ad_id: string;
  status: ReportStatus;
  from: 'check_in' | 'task';
}) => {
  const res = await api
    .post('ads/v1/report', {
      json: params,
    })
    .json<BaseResponse<string>>();

  return res;
};
