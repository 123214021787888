import lazyWithPreload from '@/utils/lazy';
import { Suspense } from 'react';

const preloadPage = (
  path: () => Promise<{
    default: React.ComponentType<any>;
  }>
) => {
  const Component = lazyWithPreload(path);
  Component.preload();
  return Component;
};

// 集中管理路由配置
const lazyRouters = [
  {
    path: '/invite',
    component: preloadPage(() => import('@/pages/Invite')),
  },
  {
    path: '/task',
    component: preloadPage(() => import('@/pages/Task')),
  },
  {
    path: '/airdrop',
    component: preloadPage(() => import('@/pages/Airdrop')),
  },
  {
    path: '/tabiParty',
    component: preloadPage(() => import('@/pages/TabiParty')),
  },
  {
    path: '/tabiParty/:partnerId',
    component: preloadPage(() => import('@/pages/TabiParty')),
  },
  {
    path: '/inviteRecord',
    component: preloadPage(() => import('@/pages/InviteRecord')),
  },
  {
    path: '/inviteRules',
    component: preloadPage(() => import('@/pages/InviteRules')),
  },
  {
    path: '/tonOverview',
    component: preloadPage(() => import('@/pages/TonOverview')),
  },
  {
    path: '/tonRecord',
    component: preloadPage(() => import('@/pages/TonRecord')),
  },
  {
    path: '/tonCashOut',
    component: preloadPage(() => import('@/pages/TonCashOut')),
  },
  {
    path: '/ton-rewards',
    component: preloadPage(() => import('@/pages/TonRewards')),
  },
  {
    path: '/leaderboard',
    component: preloadPage(() => import('@/pages/Leaderboard')),
  },
  {
    path: '/upgrade',
    component: preloadPage(() => import('@/pages/Upgrade')),
  },
  {
    path: '/mine/project/:projectTag',
    component: preloadPage(() => import('@/pages/Mine/ProjectTask')),
  },
  {
    path: '/profile',
    component: preloadPage(() => import('@/pages/Profile')),
  },
  {
    path: '/profile/:type',
    component: preloadPage(() => import('@/pages/Profile')),
  },
  {
    path: '/profile/setting',
    component: preloadPage(() => import('@/pages/Profile/Setting')),
  },
  {
    path: '/profile/viewBox/:boxId',
    component: preloadPage(() => import('@/pages/Profile/ViewBox')),
  },
  {
    path: '/draw/reward',
    component: preloadPage(() => import('@/pages/Draw/Reward/index')),
  },
  {
    path: '/draw/tabiPool',
    component: preloadPage(() => import('@/pages/Draw/TabiPool/index')),
  },
  {
    path: '/draw/tabi-arena/history',
    component: preloadPage(() => import('@/pages/Draw/TabiPool/History')),
  },
  {
    path: '/draw/casting',
    component: preloadPage(() => import('@/pages/Draw/Casting/index')),
  },
  {
    path: '/star',
    component: preloadPage(() => import('@/pages/Star/Records')),
  },
  {
    path: '/star/recharge',
    component: preloadPage(() => import('@/pages/Star/index')),
  },
  {
    path: '/star/records',
    component: preloadPage(() => import('@/pages/Star/Records')),
  },
  {
    path: '/mini-node/sell',
    component: preloadPage(() => import('@/pages/MiniNode/Sell')),
  },
  {
    path: '/mini-node/history',
    component: preloadPage(() => import('@/pages/MiniNode/History')),
  },
  {
    path: '/arena-detail/:id',
    component: preloadPage(() => import('@/pages/ArenaDetail')),
  },
  {
    path: '/arena/history',
    component: preloadPage(() => import('@/pages/ArenaDetail/History')),
  },
];

export default (() => {
  const routers = lazyRouters.map(({ path, component: Component }) => ({
    path,
    element: (
      <Suspense>
        <Component />
      </Suspense>
    ),
  }));
  return routers;
})();
