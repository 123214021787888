
export type LoginResult = {
  auth_date: string;
  first_name: string;
  hash: string;
  id: string;
  last_name: string;
  username: string;
  is_premium?: boolean
};
export const rawDataKey = 'h5_rawData'

export const getRawData = () => {
  return localStorage.getItem(rawDataKey);
};

export const setRawData = (token: string) => {
  localStorage.setItem(rawDataKey, token);
};

export const clearRawData = () => {
  localStorage.removeItem(rawDataKey);
};


