import { Status } from '@/api/mine';
import { useRequest } from 'ahooks';
import React, { useState } from 'react';
import { getAirdropList } from '@/api/task';

const AirDot = () => {
  const [showAirDot, setShowAirDot] = useState(false);


  useRequest(
    async () => {
      const res = await getAirdropList('airdrop_power');
      let showDot = false;

      res.task_list?.forEach((item) => {
        if (item.user_task_status === Status.NotFinish) {
          showDot = true;
        }
      });

      setShowAirDot(showDot);
    },
    {
      cacheKey: 'getAirDrop',
    }
  );
  return (
    <>
      {showAirDot ? (
        <div className="w-[8px] h-[8px] box-content border-white border-[2px] bg-[#EB3846] [filter:drop-shadow(0.4px_0.4px_0px_#A13C04)] absolute right-[6px] top-[2px] rounded-full"></div>
      ) : null}
    </>
  );
};

export default React.memo(AirDot);
